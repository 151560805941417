var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v(_vm._s(_vm.$t('views.winners.h1_title')))]),_c('v-alert',{attrs:{"dense":"","text":"","color":"info"},on:{"click":function($event){return _vm.scrollToBottom()}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('fa-icon',{staticClass:"ma-2 mr-4",attrs:{"icon":['fal', 'circle-info'],"size":"xl"}})]},proxy:true}])},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('views.winners.alert_info')))])]),_c('div',{staticClass:"text-center my-3"},[_c('Adslot',{attrs:{"ad-unit":"content-first","ad-class":"adslot__content-first"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.winners,"options":_vm.options,"server-items-length":_vm.totalPuzzles,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [12, 24, 48, 96],
      'items-per-page-text': 'Gewinner pro Seite',
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.dailyWinnerPrize",fn:function(ref){
    var item = ref.item;
return [(item.dailyWinnerPrize === -1)?_c('div',[_c('fa-icon',{staticClass:"light-blue--text text--darken-4 mr-2",attrs:{"icon":['fal', 'envelope'],"transform":"grow-2"}}),_vm._v(_vm._s(_vm.$t('views.winners.sent'))+" ")],1):_vm._e(),(item.dailyWinnerPrize === 0)?_c('div',[_c('fa-icon',{staticClass:"red--text text--accent-3 mr-1",attrs:{"icon":['fas', 'heart']}}),_c('hyperlink',{attrs:{"href":_vm.$t('hyperlinks.supporter.href'),"aria-label":_vm.$t('hyperlinks.supporter.purpose')}},[_vm._v(_vm._s(_vm.$t('views.winners.thank_you')))]),_vm._v("! ")],1):_vm._e(),(item.dailyWinnerPrize === 1)?_c('div',[_vm._v(" 50 €"),_c('fa-icon',{staticClass:"orange--text text--accent-3 mx-1",attrs:{"size":"lg","icon":['fab', 'amazon']}}),_vm._v(_vm._s(_vm.$t('views.winners.voucher'))+" ")],1):_vm._e(),(item.dailyWinnerPrize === 2)?_c('div',[_vm._v(" 10 €"),_c('fa-icon',{staticClass:"mx-1",attrs:{"size":"lg","icon":['fab', 'amazon']}}),_vm._v(_vm._s(_vm.$t('views.winners.voucher'))+" ")],1):_vm._e()]}},{key:"item.dailyWinnerName",fn:function(ref){
    var item = ref.item;
return [(item.dailyWinnerPrize === -1)?_c('div'):(item.dailyWinnerName === null)?_c('div',[_vm._v("********")]):_c('div',[_vm._v(_vm._s(item.dailyWinnerName))])]}},{key:"item.dailyWinnerCity",fn:function(ref){
    var item = ref.item;
return [(item.dailyWinnerPrize === -1)?_c('div'):(item.dailyWinnerCity === null)?_c('div',[_vm._v("********")]):_c('div',[_vm._v(_vm._s(item.dailyWinnerCity))])]}},{key:"item.dailyWinnerCountry",fn:function(ref){
    var item = ref.item;
return [(item.dailyWinnerPrize === -1)?_c('div'):(item.dailyWinnerCountry === null)?_c('div',[_vm._v("********")]):_c('div',[_vm._v(_vm._s(item.dailyWinnerCountry))])]}}],null,true)}),_c('div',{staticClass:"text-center my-3"},[_c('Adslot',{attrs:{"ad-unit":"content-last","ad-class":"adslot__content-last"}})],1),_c('div',{staticClass:"text-center"},[_c('btn-home',{staticClass:"my-3"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }