<template>
  <div>
    <h1>{{ $t('views.winners.h1_title') }}</h1>
    <v-alert dense text color="info" @click="scrollToBottom()">
      <template #prepend>
        <fa-icon :icon="['fal', 'circle-info']" size="xl" class="ma-2 mr-4" />
      </template>
      <p class="mb-0">{{ $t('views.winners.alert_info') }}</p>
    </v-alert>
    <div class="text-center my-3">
      <Adslot ad-unit="content-first" ad-class="adslot__content-first" />
    </div>
    <v-data-table
      :headers="headers"
      :items="winners"
      :options.sync="options"
      :server-items-length="totalPuzzles"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [12, 24, 48, 96],
        'items-per-page-text': 'Gewinner pro Seite',
      }"
    >
      <template #[`item.dailyWinnerPrize`]="{ item }">
        <div v-if="item.dailyWinnerPrize === -1">
          <fa-icon :icon="['fal', 'envelope']" class="light-blue--text text--darken-4 mr-2" transform="grow-2" />{{
            $t('views.winners.sent')
          }}
        </div>
        <div v-if="item.dailyWinnerPrize === 0">
          <fa-icon :icon="['fas', 'heart']" class="red--text text--accent-3 mr-1" /><hyperlink
            :href="$t('hyperlinks.supporter.href')"
            :aria-label="$t('hyperlinks.supporter.purpose')"
            >{{ $t('views.winners.thank_you') }}</hyperlink
          >!
        </div>
        <div v-if="item.dailyWinnerPrize === 1">
          50 €<fa-icon size="lg" :icon="['fab', 'amazon']" class="orange--text text--accent-3 mx-1" />{{ $t('views.winners.voucher') }}
        </div>
        <div v-if="item.dailyWinnerPrize === 2">
          10 €<fa-icon size="lg" :icon="['fab', 'amazon']" class="mx-1" />{{ $t('views.winners.voucher') }}
        </div>
      </template>
      <template #[`item.dailyWinnerName`]="{ item }">
        <div v-if="item.dailyWinnerPrize === -1">&nbsp;</div>
        <div v-else-if="item.dailyWinnerName === null">********</div>
        <div v-else>{{ item.dailyWinnerName }}</div>
      </template>
      <template #[`item.dailyWinnerCity`]="{ item }">
        <div v-if="item.dailyWinnerPrize === -1">&nbsp;</div>
        <div v-else-if="item.dailyWinnerCity === null">********</div>
        <div v-else>{{ item.dailyWinnerCity }}</div>
      </template>
      <template #[`item.dailyWinnerCountry`]="{ item }">
        <div v-if="item.dailyWinnerPrize === -1">&nbsp;</div>
        <div v-else-if="item.dailyWinnerCountry === null">********</div>
        <div v-else>{{ item.dailyWinnerCountry }}</div>
      </template>
    </v-data-table>
    <div class="text-center my-3">
      <Adslot ad-unit="content-last" ad-class="adslot__content-last" />
    </div>
    <div class="text-center">
      <btn-home class="my-3"></btn-home>
    </div>
  </div>
</template>

<script>
import Adslot from '@/components/Adslot.vue'
import { Com } from '@/util'
import BtnHome from '@/components/btn/Home'
import Hyperlink from '@/components/Hyperlink'

export default {
  name: 'Winners',
  components: { Adslot, Hyperlink, BtnHome },
  data() {
    return {
      headers: [
        { text: 'Jahr', align: 'center', value: 'dailyWinnerYear', sortable: false },
        { text: 'Monat', align: 'center', value: 'dailyWinnerMonth', sortable: false },
        { text: 'Platzierung', align: 'center', value: 'dailyWinnerPlacement', sortable: false },
        { text: 'Gewinn', align: 'left', value: 'dailyWinnerPrize', sortable: false },
        { text: 'Name', align: 'left', value: 'dailyWinnerName', sortable: false },
        { text: 'Ort / Stadt', align: 'left', value: 'dailyWinnerCity', sortable: false },
        { text: 'Land', align: 'left', value: 'dailyWinnerCountry', sortable: false },
      ],
      loading: false,
      options: {
        itemsPerPage: 12,
      },
      totalPuzzles: 0,
      winners: [],
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi: function () {
      this.loading = true
      const com = new Com()
      const { page, itemsPerPage } = this.options
      const requestBody = {
        page,
        itemsPerPage,
      }
      com.getDailySudokuWinners(requestBody).then((result) => {
        this.winners = result.data.winners.map((winner) => {
          const dailyWinnerMonth = this.$t(`globals.months.month_${winner.dailyWinnerMonth}`)
          return {
            ...winner,
            dailyWinnerMonth,
          }
        })
        this.totalPuzzles = result.data.total
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss"></style>
